import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { pageTitle, headerType } from '../actions';

const ComingSoon = () => {
   
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( pageTitle('Coming Soon') );
        dispatch( headerType(2) );
    }, []);

    return (
        <div className="coming-page-wrap">
            <div className="coming-soon-wrapper">
                <div className="py-5">
                    <header className="text-lowercase">
                        <div className="text-center">
                            <Link to="/">
                                <div><img src={`${process.env.REACT_APP_img_folder}/logo/logo-icon.svg`} width="80"/></div>
                                <div className="mt-3"><img src={`${process.env.REACT_APP_img_folder}/logo/logo.svg`} width="250"/></div>
                            </Link>
                        </div>
                    </header>

                    <div className="container text-center">
                        <div className="font-gotham-light-30 my-5 pt-3 pb-5">coming soon</div>
                        <div className="font-gotham-light-16 pt-4">also available soon on</div>

                        <div>
                            <Link to="#"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-playstore.svg`} className="pt-4" /></Link>
                            <Link to="#" className="ml-sm-4"><img src={`${process.env.REACT_APP_img_folder}/logo/logo-appstore.svg`} className="pt-4" /></Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default ComingSoon;