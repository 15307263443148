export const pageReducer = ( state = 'Home', action ) =>{
    switch (action.type) {
        case 'PAGETITLE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const headerTypeReducer = ( state = 1, action ) =>{
    switch (action.type) {
        case 'HEADERTYPE':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const hideHeaderFooterReducer = ( state = false, action ) =>{
    switch (action.type) {
        case 'HIDEHEADERFOOTER':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}

export const loaderReducer = ( state = 0 , action ) =>{
    switch (action.type) {
        case 'SHOWLOADER':
                state = action.payload;
                return state;
            break;
    
        default:
            return state;
            break;
    }
}
