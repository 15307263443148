import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { pageTitle, headerType } from '../actions';

import Search from '../components/Search';

const HelpCenterSelected = ({match}) => {
   
    const dispatch = useDispatch();

    const listArray = [
        { title : 'Temporarily Banned' },
        { title : 'Download and Installation' },
        { title : 'Verification' },
        { title : 'Account and Profile' },
        { title : 'Chats' },
        { title : 'Payments' },
        { title : 'Contacts' },
        { title : 'Voice and Video Calls' },
        { title : 'Security and Privacy' },
        { title : 'Status' },
        { title : 'Troubleshooting' },
        { title : 'WhatsApp Business API' },
    ];

    const [ selectedList , setSelectedList ] = useState(-1);

    const handleClickList = ( key = -1 ) => {
        key = key===selectedList ? -1 : key;
        setSelectedList(parseInt(key));
    }

    useEffect(() => {
        dispatch( pageTitle('Help Center') );
        dispatch( headerType(1) );
    }, []);


    useEffect(() => {
        let slugName = match.params.slug;
        
        if ( typeof slugName!=='undefined' ){
            
        }

    }, [match.params]);

    return (
        <>
            <div className="breadcrumbs">
                    <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="current">help center</div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="page-wrap px-4 px-sm-5">

                <Search />
                
                <section className="help-center pb-5">
                    <div className="container">
                        <div className="col-sm-10 mx-auto text-lowercase mb-5">
                            <ul className="list-subcategories fs-16">
                                {
                                    Object.entries(listArray).map( ([key, detail]) => 
                                        <li className={`${selectedList===parseInt(key) ? 'selected' : ''}`}>
                                            <div className={`py-3 px-5 cursor-pointer ${selectedList===parseInt(key) ? 'bg-gray' : ''}`} onClick={ () => handleClickList(parseInt(key)) }>{ detail.title }</div>
                                            {
                                                selectedList===parseInt(key) && 
                                                    <div className="pt-3 pb-4 px-5">content</div>
                                            }
                                        </li>
                                    )
                                }
                            
                            </ul>
                        </div>
                    </div>
                </section>
            

            </div>
        </>
    )
}

export default HelpCenterSelected;