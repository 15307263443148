import { pageReducer, headerTypeReducer, hideHeaderFooterReducer, loaderReducer }  from './pages';
import { combineReducers } from 'redux';


const allReducers = combineReducers({
    pageTitle : pageReducer,
    headerType : headerTypeReducer,
    hideHeaderFooter : hideHeaderFooterReducer,
    showLoader : loaderReducer,
});

export default allReducers;