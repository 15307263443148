export const pageTitle = (nr) =>{
    return{
        type : 'PAGETITLE',
        payload : nr
    }
}

export const headerType = (nr) =>{
    return{
        type : 'HEADERTYPE',
        payload : nr
    }
}


export const hideHeaderFooter = (nr) =>{
    return{
        type : 'HIDEHEADERFOOTER',
        payload : nr
    }
}

export const showLoader = (nr) =>{
    return{
        type : 'SHOWLOADER',
        payload : nr
    }
}