import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { pageTitle, headerType } from '../actions';
import { urlHelpCenter } from '../helpers/URLs';

import Search from '../components/Search';

const ComingSoon = ({match}) => {
   
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( pageTitle('Help Center') );
        dispatch( headerType(1) );
    }, []);


    useEffect(() => {
        let slugName = match.params.slug;
        
        if ( typeof slugName!=='undefined' ){
            
        }

    }, [match.params]);

    return (
        <>
            <div className="breadcrumbs">
                    <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="current">help center</div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="page-wrap px-4 px-sm-5">

                <Search />
                
                <section className="help-center pb-5">
                    <div className="container help-center-container py-3 py-sm-5 box-rounded text-lowercase text-center">
                        <div className="px-sm-3 px-md-5">

                            <div className="row pt-4 border-bottom">
                                
                                <div className="col-sm-6 col-md-4 mb-4">
                                    <div className="fs-18 mb-2 ff-gotham-book">General</div>
                                    <ul className="list-ask-links">
                                        <li><Link to="/">About forwarding limits</Link></li>
                                        <li><Link to="/">How to update WhatsApp</Link></li>
                                        <li><Link to="/">How to change group privacy settings</Link></li>
                                        <li><Link to={`${urlHelpCenter}/general`} className="view-all">View all questions</Link></li>
                                    </ul>
                                </div>

                                <div className="col-sm-6 col-md-4 mb-4">
                                    <div className="fs-18 mb-2 ff-gotham-book">Android</div>
                                    <ul className="list-ask-links">
                                        <li><Link to="/">How to verify your phone number</Link></li>
                                        <li><Link to="/">How to restore your chat history</Link></li>
                                        <li><Link to="/">How to manage your notifications</Link></li>
                                        <li><Link to={`${urlHelpCenter}/android`} className="view-all">View all questions</Link></li>
                                    </ul>
                                </div>

                                <div className="col-sm-6 col-md-4 mb-4">
                                    <div className="fs-18 mb-2 ff-gotham-book">iPhone</div>
                                    <ul className="list-ask-links">
                                        <li><Link to="/">How to restore your chat history</Link></li>
                                        <li><Link to="/">How to manage your notifications</Link></li>
                                        <li><Link to="/">How to use status</Link></li>
                                        <li><Link to={`${urlHelpCenter}/iphone`} className="view-all">View all questions</Link></li>
                                    </ul>
                                </div>

                            </div>

                            <div className="row pt-4 border-bottom">
                                
                                <div className="col-sm-6 col-md-4 mb-4">
                                    <div className="fs-18 mb-2 ff-gotham-book">Web and Desktop</div>
                                    <ul className="list-ask-links">
                                        <li><Link to="/">About WhatsApp Web and Desktop</Link></li>
                                        <li><Link to="/">How to log in or out</Link></li>
                                        <li><Link to="/">How to manage your notifications</Link></li>
                                        <li><Link to={`${urlHelpCenter}/web-desktop`} className="view-all">View all questions</Link></li>
                                    </ul>
                                </div>

                                <div className="col-sm-6 col-md-4 mb-4">
                                    <div className="fs-18 mb-2 ff-gotham-book">KaiOs</div>
                                    <ul className="list-ask-links">
                                        <li><Link to="/">How to verify your phone number</Link></li>
                                        <li><Link to="/">How to edit your profile</Link></li>
                                        <li><Link to="/">How to send media, contacts, or location</Link></li>
                                        <li><Link to={`${urlHelpCenter}/kaios`} className="view-all">View all questions</Link></li>
                                    </ul>
                                </div>

                                <div className="col-sm-6 col-md-4 mb-4">
                                    <div className="fs-18 mb-2 ff-gotham-book">Bizcom Plus</div>
                                    <ul className="list-ask-links">
                                        <li><Link to="/">About Bizcom Plus products</Link></li>
                                        <li><Link to="/">How to download the Bizcom Plu app</Link></li>
                                        <li><Link to="/">How to edit your business profile</Link></li>
                                        <li><Link to={`${urlHelpCenter}/bizcomplus`} className="view-all">View all questions</Link></li>
                                    </ul>
                                </div>

                            </div>

                            <div className="row pt-4">
                                
                                <div className="col-sm-6 col-md-4 mb-4">
                                    <div className="fs-18 mb-2 ff-gotham-book">Web and Desktop</div>
                                    <ul className="list-ask-links">
                                        <li><Link to="/">About WhatsApp Web and Desktop</Link></li>
                                        <li><Link to="/">How to log in or out</Link></li>
                                        <li><Link to="/">How to manage your notifications</Link></li>
                                        <li><Link to={`${urlHelpCenter}/web-desktop`} className="view-all">View all questions</Link></li>
                                    </ul>
                                </div>

                            </div>

                        </div>
                    </div>
                </section>
            

            </div>
        </>
    )
}

export default ComingSoon;