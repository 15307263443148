import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { pageTitle } from '../actions';

const NotFoundPage = () => {

    const history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch( pageTitle('404 | Page Not Found') );
    }, []);

    return (
        <div className="not-found-page-main-container position-fixed w-100 h-100 d-flex align-items-center justify-content-center " style={{ top: 0, paddingBottom: '155px'}}>
            <div className="container">
                <div className="col-8 mx-auto text-center">
                    <img src={ process.env.REACT_APP_img_folder+'/404.svg'} width="100%"></img>
                    <h2 className="text-uppercase my-5">Oops! Page not found</h2>
                    
                    <div className=" d-flex align-items-center justify-content-center ">
                        <a href="#" className="cursor-pointer" onClick={ () => history.goBack() }>go back</a>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NotFoundPage;