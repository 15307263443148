import React, { useState, useEffect }  from 'react';
import {  BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import {  useSelector } from 'react-redux';
import 'react-confirm-alert/src/react-confirm-alert.css';

import Home from './pages/Home';
import HelpCenterSelected from './pages/HelpCenterSelected';
import ComingSoon from './pages/ComingSoon';
import NotFoundPage from './pages/NotFoundPage';
import Page from './pages/Page';
import About from './pages/About';
import Careers from './pages/Careers';
import GetInTouch from './pages/GetInTouch';

import PageHeader from './layout/PageHeader';
import PageHeaderLogo from './layout/PageHeaderLogo';
import Footer from './layout/Footer';


import { urlHelpCenter, urlPage } from './helpers/URLs';


function App() {

  const pageTitle = useSelector( state => state.pageTitle);
  const headerType = useSelector( state => state.headerType);
  const hideHeaderFooter = useSelector( state => state.hideHeaderFooter);
  const showLoader = useSelector( state => state.showLoader);
  
  const [ pageSiteTitle , setPageSiteTitle ] = useState('Home');
  const [ headerTypeSite , setHeaderTypeSite ] = useState(1);
  const [ noHeaderFooter , setNoHeaderFooter ] = useState(false);

  const headerHtml = () => {
      let headerContent = <PageHeader />

      if (headerTypeSite===2){
         headerContent = <PageHeaderLogo />
      }
      return headerContent;
  }

  useEffect( () => {
    setPageSiteTitle(pageTitle);
  }, [pageTitle]);

  useEffect( () => {
    setHeaderTypeSite(headerType);
  }, [headerType]);

  useEffect( () => {
    setNoHeaderFooter(hideHeaderFooter);
  }, [hideHeaderFooter]);

  return (

    <HelmetProvider>
      <Router>

          <Helmet>
            <title>{`${process.env.REACT_APP_site_title} | ${pageSiteTitle}`}</title>
          </Helmet>

            { ! noHeaderFooter && headerHtml() }

            <div className="page-content">
            
              <Switch>
                  <Route path="/" exact component={ComingSoon} />
                  <Route path={`${urlHelpCenter}`} exact component={Home} />
                  <Route path={`${urlHelpCenter}/:slug`} exact component={HelpCenterSelected} />
                  <Route path={`${urlPage}/about`} exact component={About} />
                  <Route path={`${urlPage}/careers`} exact component={Careers} />
                  <Route path={`${urlPage}/get-in-touch`} exact component={GetInTouch} />

                  <Route path={`${urlPage}/help-center`} exact component={Home} />
                  <Route path={`${urlPage}/terms-conditions`} exact component={Page} />
                  <Route path={`${urlPage}/terms-conditions-clean`} exact component={Page} />
                  <Route path={`${urlPage}/privacy-policy`} exact component={Page} />
                  <Route path={`${urlPage}/site-map`} exact component={Page} />
                  
                  <Route path={`${urlPage}/licenses`} exact component={Page} />
                  <Route path={`${urlPage}/licenses-clean`} exact component={Page} />
                  
                  <Route path="*" component={NotFoundPage} />
              </Switch>

            </div>
            
            { showLoader===1 && <div className="main-loader show"><img src={ `${process.env.REACT_APP_img_folder}/loader.gif`} /></div> }

            { ! noHeaderFooter && <Footer /> }

      </Router>
    </HelmetProvider>
  );
}

export default App;
