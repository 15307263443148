import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { pageTitle, headerType, hideHeaderFooter} from '../actions';

const Page = ({match}) => {

    const dispatch = useDispatch();
    
    const [ pageContent, setPageContent ] = useState('');
    const [ pageHeaderTitle, setPageHeaderTitle ] = useState('');

    const pageNamesArray = {
        'terms-conditions' : 'Terms and Conditions',
        'terms-conditions-clean' : 'Terms and Conditions',
        'privacy-policy' : 'Privacy Policy',
        'site-map' : 'Site Map',
        'licenses' : 'Licenses',
        'licenses-clean' : 'Licenses'
    };

    const getHtmlDetail = () => {

        const pathname = window.location.pathname; 
        let segment = pathname.split('/');
        segment = segment.filter(Boolean);
        
        let pageContentRequest = '';

        let urlSlug = segment.pop();
        if ( urlSlug!==undefined && urlSlug in pageNamesArray){
            urlSlug = urlSlug.toString().toLowerCase();

            const pageSiteTitle = pageNamesArray[urlSlug];
            dispatch( pageTitle(pageSiteTitle) );

            setPageHeaderTitle( ['terms-conditions-clean', 'licenses-clean'].includes(urlSlug) ? '' :pageSiteTitle);

            if ( ['terms-conditions-clean', 'licenses-clean'].includes(urlSlug) ){
                dispatch( hideHeaderFooter(true) );
            }

            if (  ['licenses', 'licenses-clean'].includes(urlSlug)  ){
                pageContentRequest = <div>
                                        <p>enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim failisis ravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.</p>
                                        <p>enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim facilisis gravida neque convallis. scelerisque in dictum non onsectetur a erat nam at lectus.</p>
                                    </div>

            }else{
               
                pageContentRequest = <div>
                                        <p> Last updated June 5, 2018. Replaces all prior versions.</p>

                                        <p>These General Terms of Use ("General Terms"), along with any applicable Additional Terms (see section 1.2 below) and the Subscription and Cancellation terms (collectively "Terms") govern your use of our website, customer support, and services such as Creative Cloud (collectively “Services”) and software that we include as part of the Services, as well as any applications, Sample Files and Content Files (defined below), scripts, source code, instruction sets, and related documentation (collectively “Software”). If you have entered into another agreement with us concerning specific Services or Software, then the terms of that agreement control where it conflicts with the Terms. As discussed more in section 4 below, you retain all rights and ownership you have in your Content (defined below).</p>
                                        
                                        <p>You must be 13 or older to register for an individual Adobe ID. Schools that participate in the primary and secondary education named user offering may issue a child under 13 an enterprise-level Adobe ID, but only after obtaining express </p>
                            
                                        <p>1. <span className="font-gotham-medium-14">Introduction.</span> These Website Standard Terms And Conditions (these “Terms” or these “Website Standard Terms And Conditions”) contained herein on this webpage, shall govern your use of this website, including all pages within this website (collectively referred to herein below as this “Website”). These Terms apply in full force and effect to your use of this Website and by using this Website, you expressly accept all terms and conditions contained herein in full. You must not use this Website, if you have any objection to any of these Website Standard Terms And Conditions.</p>
                                    
                                        <p>This Website is not for use by any minors (defined as those who are not at least 18 years of age), and you must not use this Website if you a minor.</p>
                            
                                        <p>2. <span className="font-gotham-medium-14">Intellectual Property Rights.</span> Other than content you own, which you may have opted to include on this Website, under these Terms, [Sender.Company] and/or its licensors own all rights to the intellectual property and material contained in this Website, and all such rights are reserved. You are granted a limited license only, subject to the restrictions provided in these Terms, for purposes of viewing the material contained on this Website.</p>
                            
                                        <p>3. <span className="font-gotham-medium-14">Restrictions.</span> You are expressly and emphatically restricted from all of the following:</p>
                                        
                                        <ul>
                                            <li>publishing any Website material in any media;</li>
                                            <li>selling, sublicensing and/or otherwise commercializing any Website material;</li>
                                            <li>using this Website in any way that is, or may be, damaging to this Website;</li>
                                            <li>using this Website in any way that impacts user access to this Website;</li>
                                            <li>using this Website contrary to applicable laws and regulations, or in a way that causes, or may cause, harm to the Website, or to any person or business entity;</li>
                                            <li>engaging in any data mining, data harvesting, data extracting or any other similar activity in relation to this Website, or while using this Website;</li>
                                            <li>using this Website to engage in any advertising or marketing;</li>
                                            <li>Certain areas of this Website are restricted from access by you and [Sender.Company] may further restrict access by you to any areas of this Website, at any time, in its sole and absolute discretion. Any user ID and password you may have</li>
                                            <li>for this Website are confidential and you must maintain confidentiality of such information.</li>
                                        </ul>
                            
                                    </div>

            }

        }

        setPageContent(pageContentRequest);

    }

    useEffect(() => {
        dispatch( headerType(1) );
    }, []);

    useEffect( () => {
        getHtmlDetail();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [match.params]);

    return (
        <>
            { pageHeaderTitle!=='' && 
                <div className="breadcrumbs">
                        <div className="container breadcrumbs-content">
                        <ul className="breadcrumbs-nav">
                            <li>
                                <div class="current">{pageHeaderTitle}</div>
                            </li>
                        </ul>
                    </div>

                </div>
            }

            <div className="page-wrap pt-4">

                <div className={`main-content ${ pageHeaderTitle!=='' ? 'container' : ''} `}>
                    <div className="page-content fs-14 text-lowercase">
                        <div className="col-12 col-md-10 mx-auto pb-5">
                            <div className="pb-5">{pageContent}</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Page;