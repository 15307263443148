import axios from 'axios';
import { externalIPgeolocationDB } from './APIUrls';

export const getCurrentLocationbyGEO = async () =>{
    let responseDetail = {};

    try {
        const responseURL = await axios.get(  externalIPgeolocationDB );
        if ( parseInt(responseURL.status)===200){
            responseDetail =  responseURL.data;
        }

    } catch (e) {
        
    }

    return responseDetail;
}

