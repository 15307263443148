import axios from 'axios';
import { urlSendGetInTouch, urlGeneralSendSystemEmail } from './APIUrls';

const API_TOKEN_BIZCOM = process.env.REACT_APP_token_bizcom;


export const sendAppReports = async ( formData = {} ) =>{
    let responseDetail = { status: 0 };

    try {
        const responseURL = await axios.post(  urlSendGetInTouch , formData );
        console.log(responseURL);
        if ( parseInt(responseURL.data)===1 ){
            responseDetail =  { status: 1 };
        }

    } catch (e) {
        
    }

    return responseDetail;
}


export const sendAppReportsBizcom = async ( passval = {} ) =>{
    let responseDetail = {};

    if ( Object.entries(passval).length>0 ){
        try {
            passval = { ...passval, ...{ api_token : API_TOKEN_BIZCOM.toString() } };
            const responseURL = await axios.post(  urlGeneralSendSystemEmail , passval );
            if ( parseInt(responseURL.status)===201 ){
                responseDetail =  responseURL.data;
            }

        } catch (e) {
            
        }
    }

    return responseDetail;
}

