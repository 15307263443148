import { confirmAlert } from 'react-confirm-alert'; 

class UtilitiesHelper {
    
    
    // FUNCTIONS ////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

    isEmpty = ( s ) =>  {
        return ( ( typeof s==='undefined' ) || (s === null) || (s.length === 0));
    }

    statusLabels = (n) => {
        return ( n===1 ) ? 'active' : 'inactive';
    }

    featuredLabels = (n) => {
        return ( n===1 ) ? 'yes' : 'no';
    }

    isEmail = (email) => {
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
   }

    checkSpecialChar = (string) =>{
        //var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
        var format = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/;
        return format.test(string) ? true : false;
    }

    sliceIntoChunks = (arr, chunkSize) => {
        const res = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            const chunk = arr.slice(i, i + chunkSize);
            res.push(chunk);
        }
        return res;
    }

    removeSpecialChar = (text) => {
        //const  regExpr = /[^a-zA-Z0-9-. ]/g; // excluded space and .
        const  regExpr = /[^a-zA-Z0-9-]/g;
        return text.replace(regExpr, "");
    }

    messagePopup = ( title = 'Error', message = '' ) => {
        confirmAlert({
            title: title,
            message: message,
            overlayClassName : title.toLowerCase(),
            buttons: [
                { label: 'Close',  onClick: () => {} }
            ]
        }); 
    }
    
    counterMore = ( currentCounter = 0, maxCounter = 99 ) => {
        let counterReturn = parseInt(currentCounter)>parseInt(maxCounter) ? maxCounter.toString()+'+' : currentCounter;
        counterReturn = counterReturn.toString();
        return counterReturn;
    }

    months = (num = -1) => {
        let months = ['January','February','March','April','May','June','July','August','September','October','November','December'];
        if ( parseInt(num)>-1 ){
            months = months[num];
        }
        return months;
    }

    passwordValidation = ( requestPassword = '', showHtml = false ) => {
        let errorMessage = '', valid = true, htmlList = '', errorCounter = 0, genetalHtml = '';

        const errorLength = `be at least eight characters long`;
        const errorUppercase = `have at least one uppercase`;
        const errorLowercase = `have at least one lowercase`;
        const errorNumber = `have at least one number`;
        const errorSymbol = `have at leat special character`;

        let validList = { long : true, lowerCase : true, upperCase : true, numeric : true, symbol : true };
        

        if (requestPassword.length < 8) {
            errorMessage = errorLength;
            errorCounter++;
            validList = {...validList, long : false };
        }

        if (requestPassword.search(/[a-z]/) < 0) {
            errorMessage = errorLowercase;
            errorCounter++;
            validList = {...validList, lowerCase : false };
        }
        if (requestPassword.search(/[A-Z]/) < 0) {
            errorMessage = errorUppercase;
            errorCounter++;
            validList = {...validList, upperCase : false };
        }
        if (requestPassword.search(/[0-9]/) < 0) {
            errorMessage = errorNumber;
            errorCounter++;
            validList = {...validList, numeric : false };
        }
        if (requestPassword.search(/[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?]+/) < 0) {
            errorMessage = errorSymbol;
            errorCounter++;
            validList = {...validList, symbol : false };
        }

        valid = parseInt(errorCounter)>0 ? false : true;

        if ( showHtml===true ){
            htmlList = `<ul class="password-verification-guide-list">
                            <li>password must:</li>
                            <li class="${ validList.long===true ? 'passed' : '' }">${errorLength}</li>
                            <li class="${ validList.upperCase===true ? 'passed' : '' }">${errorUppercase}</li>
                            <li class="${ validList.lowerCase===true ? 'passed' : '' }">${errorLowercase}</li>
                            <li class="${ validList.numeric===true ? 'passed' : '' }">${errorNumber}</li>
                            <li class="${ validList.symbol===true ? 'passed' : '' }">${errorSymbol}</li>
                        </ul>`
        }

        if ( requestPassword==='' ){ htmlList = ''; }
        if ( valid===false ){ genetalHtml = '<div class="fs-14 font-gotham-light text-gray mt-n2 pl-2 pb-3">password does not match the password criteria</div>'; }

        return { response : valid, errorMsg : errorMessage, html : htmlList, generalHtml : genetalHtml };

    }

    systemDateFormatted = ( dateRequest = '') => {
        const _SELF = this;
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        let month = currentDate.getMonth();
        month = parseInt(month.toString().length)===1 ? '0'+month: month;

        let date = currentDate.getDate() // 23
        date = parseInt(date.toString().length)===1 ? '0'+date: date;

        const year = currentDate.getFullYear() // 2019

        return `${date}/${month}/${year}`;
        //return `${month}/${date}/${year}`;
    } 

    currentDateFormatted = ( dateRequest = '') => {
        const _SELF = this;
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        const monthIndex = currentDate.getMonth();
        const month_name = _SELF.months(monthIndex);
        
        const year = currentDate.getFullYear() // 2019
        const date = currentDate.getDate() // 23

        return `${date} ${month_name} ${year}`;
    } 
    
    returnDateFormat = ( dateRequest = '') => {
        const _SELF = this;
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        let month = currentDate.getMonth();
        month = parseInt(month.toString().length)===1 ? '0'+month: month;

        let date = currentDate.getDate() // 23
        date = parseInt(date.toString().length)===1 ? '0'+date: date;

        const year = currentDate.getFullYear() // 2019

        return `${date}-${month}-${year}`;
    }

    convertTo24Hour = ( dateRequest = '' ) => {
        let returnValue = dateRequest;
        if ( ! this.isEmpty(dateRequest) ){
            const currentDate = new Date( '12-12-12 ' + dateRequest );
            returnValue = `${currentDate.getHours()}:${currentDate.getMinutes()}`;
        }
        return returnValue;
    }

    returnTimeFormat = ( dateRequest = '', format = 12) => {
        const _SELF = this;
        let formatLabel = 'am';
        let currentDate = _SELF.isEmpty(dateRequest) ? new Date() : new Date( dateRequest );

        let hour = currentDate.getHours();
        if ( parseInt(hour)>=12){ formatLabel = 'pm'; }
        if ( format===12 ){
            hour = hour % 12;
            if ( parseInt(hour)===0 ){
                hour = 12;
            }
        }
        hour = parseInt(hour.toString().length)===1 ? '0'+hour: hour;

        let min = currentDate.getMinutes()
        min = parseInt(min.toString().length)===1 ? '0'+min: min;

        return `${hour}:${min} ${formatLabel}`;
    }

    numberWithCommas = (x , symbol = ',') => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, symbol);
    }
    
    numberFormat = (number, digit ) => {
        let decimal = 2;
        if( !this.isEmpty(digit) ){ decimal =  digit; } 
        return parseFloat(number).toFixed(decimal);
    }
    
    number_format = (number, digit = 0, symbol = ',')  => {
        number = this.numberFormat(number, digit);
        number = this.numberWithCommas(number, symbol);
        return number;
    }
        
    validate_image_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['jpg', 'png', 'jpeg'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }

    validate_document_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['doc', 'docx', 'ppt', 'pptx', 'txt', 'pdf', 'xlsx', 'xls'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }


    validate_video_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['mp4', 'avi' ,'mov', 'flv', 'wmv'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }


    validate_audio_ext = ( fileName ) => {
        let fileExtension = fileName.substr((fileName.lastIndexOf('.') + 1)).toString().toLowerCase();
        let availble_ext = ['mp3', 'acc'];
        return (availble_ext.indexOf(fileExtension) !== -1) ? true : false;
    }
    
    wordsCounter = ( textString ) => {
        let counter = 0;
        if ( !this.isEmpty(textString) ){
            let counterArr = textString.split(' ');
            counter = counterArr.length;
        }
        return parseInt(counter);
    }

    charactersCounter = ( textString ) => {
        let counter = 0;
        if ( !this.isEmpty(textString) ){
            textString = textString.replaceAll(' ', '');
            counter = textString.toString().length;
        }
        return parseInt(counter);
    }

    contactNumberValidate = ( textString ) => {
        let result = false;
        if ( !this.isEmpty(textString) ){
            const counter = textString.toString().length;
            result = ( counter<6 || counter>11 ) ? false : true;
        }
        return result;
    }

    removeBackSlash = (str = '' ) => {
        let strFormatted = str.split("\\n");
        strFormatted = strFormatted.map( text => this.isEmpty(text) ? '<br />' : '<div>'+text+'</div>' );
        str = strFormatted.join('');
       return str.replaceAll(/\\/g, "");
    }

    replaceNewlineToSpace = (str = '' ) => {
        return str.split("\\n").filter(Boolean).join(' ');
    }

    basicJoinObjectValues = ( objectArray, separator = '-' ) => {
        let keyArr = [];
        Object.entries(objectArray).map( ([key, row]) => {
            keyArr.push(row);
        });
        keyArr = parseInt(keyArr.length)===0 ? '' : keyArr.join('-');
        return keyArr.toString();
    }

    formTypes = (type = 0) =>  {
        let types_labels = [
                { value : 1, label :'appointment' },
                { value : 2, label :'reservation' },
                { value : 3, label : 'feedback' }
            ];
        return types_labels;
    }

    fieldSorter = (fields) => (a, b) => fields.map(o => {
        let dir = 1;
        if (o[0] === '-') { dir = -1; o=o.substring(1); }
        return a[o] > b[o] ? dir : a[o] < b[o] ? -(dir) : 0;
    }).reduce((p, n) => p ? p : n, 0)
    
    currentUTCTime = ()  => {
        const now = new Date();        
        const utc_timezone  = now.toUTCString();
        return utc_timezone;
    }

    arrayUnique = ( dataArray) => {
        dataArray = dataArray.filter(function(item, pos) {
            return dataArray.indexOf(item) === pos;
        })
        return dataArray;
    }

    removeValueInArray = (dataArray, value) => {
        let index = dataArray.indexOf(value);
        if (index !== -1) {
            dataArray.splice(index, 1);
        }
        return dataArray;
    }

    elementDisabledStatus = ( id, action = false ) => {
        if ( !this.isEmpty(id) ){
            let elemSelected = document.getElementById(id);
            if (typeof(elemSelected) != 'undefined' && elemSelected != null){
                elemSelected.disabled = action;

                if (action){
                    elemSelected.classList.add("animate");
                }else{
                    elemSelected.classList.remove("animate");
                }
            }
        }
    }

    randonLetter = (length = 2 ) => {
        var result           = '';
        var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'; //'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for ( var i = 0; i < length; i++ ) {
           result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
     }

    generateSignupCode = () => {
        //let numeric = ( Math.floor(Math.random() * 10) + '' + Math.floor(Math.random() * 10) ); // max 9 - max 9
        return 'Q1w2'; //numeric + this.randonLetter();
    }

    logoFolderByAccountType = (type = 0) => {
        let url = '';
        if ( type>0 ){
            if ( type===1){ url = process.env.REACT_APP_images_external_url+'/individual_user'; }
            else if ( type===3){ url = process.env.REACT_APP_images_external_url+'/company'; }
            else if ( type===4){ url = process.env.REACT_APP_images_external_url+'/team_member'; }
        }
        return url;
    }

    toNormalArrayObject = (arr) =>{
        let arr_updated = [];
        for (const [key, value] of Object.entries(arr)) {
            arr_updated.push(value);
        }
        return arr_updated;
    }  
    
    getUrlSegments = () => {
        const pathname = window.location.pathname; 
        let segment = pathname.split('/');
        return segment.filter(Boolean);
    }

    formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }


    sortAccounts = ( accountsList = {} ) => {
        let accountListTemp = [];
        if ( Object.entries(accountsList).length>0 ){

            Object.entries(accountsList).map( ( [acctid, acctdetail] ) => {
                accountListTemp.push(acctdetail);
            });
            accountListTemp.sort((accountListTemp,b)=> (accountListTemp.account_type > b.account_type ? 1 : -1));
        }

        return accountListTemp;
    }

    calculateDistance = ( lat1 = '', lon1 = '', lat2 = '', lon2 = '', unit = 'K' ) => {
        let dist = 0 ;

        if ( lat1!=='' && lon1!=='' && lat2!=='' && lon2!=='' ){
 
            let radlat1 = Math.PI * lat1/180;
            let radlat2 = Math.PI * lat2/180;
            let radlon1 = Math.PI * lon1/180;
            let radlon2 = Math.PI * lon2/180;
            let theta = lon1-lon2;
            let radtheta = Math.PI * theta/180;
            
            dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
            dist = Math.acos(dist);
            dist = dist * 180/Math.PI;
            dist = dist * 60 * 1.1515;

            if (unit==="K") { dist = dist * 1.609344 }
            if (unit==="N") { dist = dist * 0.8684 }
        }

        return dist;
    }

    sortCalculateDistance = ( providedArray = {}, putZeroLast = 0 ) => {
        const SELF = this;

        let sortedList = [];
        if ( Object.entries(providedArray).length>0 ){
            const uniqueNodes = [];
            
            let latitude_init = providedArray[0]["latitude"];
            let longitude_init = providedArray[0]["longitude"];

            let sessionLocation = sessionStorage.getItem(process.env.REACT_APP_session_location);
            if ( sessionLocation!==null ){
                sessionLocation = JSON.parse(sessionLocation);
                latitude_init = sessionLocation.latitude ?? 0;
                longitude_init = sessionLocation.longitude ?? 0;
            }


            Object.entries( providedArray ).map( ( [ key, row ] ) => {
                const distance = SELF.calculateDistance( latitude_init, longitude_init ,row.latitude, row.longitude, "K");
                uniqueNodes.push({ ...row, distance : distance });
            });

            uniqueNodes.sort(function(a, b) { 
                return a.distance - b.distance;
            });

            sortedList = uniqueNodes;

            if ( putZeroLast===1 ){
                const distanceZero = uniqueNodes.filter( member => ( parseInt(member.distance) === 0 ) )  ?? [];
                let distanceGreater = uniqueNodes.filter( member => ( parseInt(member.distance) > 0 ) )  ?? [];

                distanceGreater.sort(function(a, b) { 
                    return a.distance - b.distance;
                });

                sortedList = [...distanceGreater, ...distanceZero ];
            }
        }

        return sortedList;
    }

    
    getUrlMetaDetail = ( providedMeta = {} ) => {
        let detail = { title : '', image : '', description : '' };
        if ( Object.keys(providedMeta).length>0 ){

            if ( providedMeta.hasOwnProperty('title') ){  detail = {...detail, title : providedMeta.title };  }

            if ( providedMeta.hasOwnProperty('twitter:title') ){ detail = {...detail, title : providedMeta['twitter:title'] }; }

            if ( providedMeta.hasOwnProperty('facebook:title') ){  detail = {...detail, title : providedMeta['facebook:title'] };  }

            if ( providedMeta.hasOwnProperty('description') ){ detail = {...detail, title : providedMeta.description }; }

            if ( providedMeta.hasOwnProperty('twitter:description') ){ detail = {...detail, description : providedMeta['twitter:description'] }; }

            if ( providedMeta.hasOwnProperty('facebook:description') ){ detail = {...detail, description : providedMeta['facebook:description'] }; }

            if ( providedMeta.hasOwnProperty('image') ){ detail = {...detail, image : providedMeta.image }; }

            if ( providedMeta.hasOwnProperty('twitter:image') ){  detail = {...detail, image : providedMeta['twitter:image'] }; }

            if ( providedMeta.hasOwnProperty('facebook:image') ){ detail = {...detail, image : providedMeta['facebook:image'] };  }

        }
        return detail;
    }

    getBrowerAgent = () => {

        navigator.browserDetection = (function(){
            var ua= navigator.userAgent, tem, 
            M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
            if(/trident/i.test(M[1])){
                tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
                return 'IE '+(tem[1] || '');
            }
            if(M[1]=== 'Chrome'){
                tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
                if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
            }
                M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
                if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
                return M[0];//M.join(' ');
        })();
        
        return navigator.browserDetection.toLowerCase(); // outputs: `Chrome 92`
    }

}

export default new UtilitiesHelper();

