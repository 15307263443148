import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {

    //const contactNumber = '+971 4 584 7083';
    const siteTitle = process.env.REACT_APP_site_title;

    return (
        <footer className="footer text-lowercase">
           <div className="container py-4 my-1">

               <div className="row mt-1">

                   

                    <div className="col-sm-6 col-md-3 mb-4 mb-sm-4  mb-md-0">
                        <h3>Explore</h3>
                        <ul className="footer-nav">
                            {/* <li><a href="https://bizcomplus.com/page/terms-conditions" target="_blank">Terms & Conditions</a></li>
                            <li><a href="https://bizcomplus.com/page/privacy-policy" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://bizcomplus.com/page/site-map" target="_blank">Site Map</a></li> */}
                            <li><Link to="/help-center">Help Center</Link></li>
                            <li><Link to="/page/terms-conditions">Terms & Conditions</Link></li>
                            <li><Link to="/page/privacy-policy">Privacy Policy</Link></li>
                            <li><Link to="/page/site-map">Site Map</Link></li>
                        </ul>
                    </div>

                    <div className="col-sm-6 col-md-3  mb-4 mb-sm-4  mb-md-0">
                        <h3>Download</h3>
                        <ul className="footer-nav">
                            <li><a href="#">iphone</a></li>
                            <li><a href="#">android</a></li>
                        </ul>
                    </div>

                    <div className="col-sm-6 col-md-3   mb-5 mb-sm-4  mb-md-0">
                        <h3>company</h3>
                        <ul className="footer-nav">
                            <li><a href="https://bizcom247.com" target="_blank">bizcom247</a></li>
                            <li><Link to="/page/about">about</Link></li>
                            <li><Link to="/page/careers">careers</Link></li>
                            <li><Link to="/page/get-in-touch">get in touch</Link></li>
                        </ul>
                    </div>

                    <div className="d-none d-sm-none d-md-inline col-md-3  mb-dm-0">
                        <div style={{ backgroundImage: `url(${process.env.REACT_APP_img_folder}/logo/logo.svg)` }} className="footer-logo"></div>
                        <div className="mt-2">copyright &copy; {  new Date().getFullYear() }</div>

                        <div className=" mt-3 mb-2">follow us on</div>
                        <ul className="footer-social">
                            <li><a href="https://www.facebook.com"  rel="noreferrer" alt="facebook" title="facebook" target="_blank" className="facebook"></a></li>
                            <li><a href="https://www.linkedin.com"  rel="noreferrer" alt="linkedin" title="linkedin" target="_blank" className="linkedin"></a></li>
                            <li><a href="https://www.instagram.com"  rel="noreferrer" alt="instagram" title="instagram" target="_blank" className="instagram"></a></li>
                            <li><a href="https://twitter.com"  rel="noreferrer" alt="twitter" title="twitter" target="_blank" className="twitter"></a></li>
                            <li><a href="https://www.youtube.com"  rel="noreferrer" alt="youtube" title="youtube" target="_blank" className="youtube"></a></li>
                        </ul>
                    </div>


                    <div className="d-sm-inline d-md-none col-sm-6 col-md-3  mb-4 mb-sm-4 mb-md-0 text-center">
                        <div style={{ backgroundImage: `url(${process.env.REACT_APP_img_folder}/logo/logo.svg)` }} className="footer-logo mx-auto"></div>
                        <div className="mt-2 mt-sm-5">copyright &copy; {  new Date().getFullYear() }</div>

                        <div className="d-flex justify-content-center mt-2">
                            <ul className="footer-social">
                                <li><a href="https://www.facebook.com"  rel="noreferrer" alt="facebook" title="facebook"  target="_blank" className="facebook"></a></li>
                                <li><a href="https://www.linkedin.com"  rel="noreferrer" alt="linkedin" title="linkedin" target="_blank" className="linkedin"></a></li>
                                <li><a href="https://www.instagram.com"  rel="noreferrer" alt="instagram" title="instagram" target="_blank" className="instagram"></a></li>
                                <li><a href="https://twitter.com"  rel="noreferrer" alt="twitter" title="twitter" target="_blank" className="twitter"></a></li>
                                <li><a href="https://www.youtube.com"  rel="noreferrer" alt="youtube" title="youtube" target="_blank" className="youtube"></a></li>
                            </ul>
                        </div>

                    </div>

               </div>
           </div>
        </footer>
    )
}

export default Footer;