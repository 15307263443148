import React, { useEffect, useState } from 'react';

const Search = (props) => {

    const [ searchInputVisibilityBool , setSearchInputVisibilityBool ] = useState(false);
    const [ searchInputKeywords , setSearchInputKeywords ] = useState('');

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let  value = e.target.value;

        if ( fieldName==='input_search_faq'){
            setSearchInputKeywords(value);
            setSearchInputVisibilityBool( value==='' ? false : true );
        }
    }

    const handleOnBlurInputSearch = () => {
        setSearchInputVisibilityBool( false );
    }


    useEffect( () => {

    }, []);
    

    return (
        <section className="search-center py-sm-5">
            <div className="container">
                <div>
                    <h4 className="text-center d-none d-sm-block">how can we help you?</h4>
                    <div className="col-md-6 mx-auto px-0 py-4">
                        <div className="position-relative">
                            <input type="text" name="input_search_faq" className="w-100 input-search-faq" placeholder="type keywords to find answers" value={searchInputKeywords} onChange={ (e) => pushValue(e, 'input_search_faq') } onBlur={ () => handleOnBlurInputSearch() } ></input>
                            {
                                searchInputVisibilityBool &&
                                    <ul className="box-dropdown-search-suggestion mt-2">
                                        <li>content will be here...</li>
                                    </ul>
                            }
                        
                        </div>
                    </div>
                    <div className="text-center  d-none d-sm-block">You can also browse the topics below to find what you are looking for.</div>
                </div>
            </div>
        </section>
    )
}

export default Search;