import React from 'react';
import { Link } from 'react-router-dom';

const PageHeaderLogo = () => {

    return (
            <header className="page-header-site text-lowercase py-4 px-3 px-sm-5 position-relative z-index-1 ">
                <div className="container">

                    <div className="page-header--nav d-flex align-items-center position-relative">
                        <div>
                            <Link to="/" className="d-block d-sm-inline text-center"><img src={`${process.env.REACT_APP_img_folder}/logo/logo.svg`} width="120"/></Link>
                        </div>

                    </div>

                </div>

            </header>
    )
}

export default PageHeaderLogo;