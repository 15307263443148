import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { pageTitle, headerType } from '../actions';

const About = () => {
   
    const dispatch = useDispatch();


    useEffect(() => {
        dispatch( pageTitle('About') );
        dispatch( headerType(1) );
    }, []);

    return (
        <>
            <div className="breadcrumbs">
                    <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="current">about</div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="page-wrap px-4 px-sm-5">
                <div className="container py-4">

                    {
                         [...Array(3).keys()].map( ( key ) => 
                            <>
                                <h3 className="fs-18 ff-gotham-book pt-2">our app</h3>

                                <p className="line-height-1-3">enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim failisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim facilisis gravida neque convallis. scelerisque in dictum non consectetur a erat nam at lectus.enim facilisis gravida neque convallis. scelerisque in dictum non onsectetur a erat nam at lectus.</p>
                            </>
                         )
                    }
                   
            
                </div>
            </div>
        </>
    )
}

export default About;