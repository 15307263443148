import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import jQuery from 'jquery';

import { pageTitle, headerType, showLoader } from '../actions';
import UtilitiesHelper from  '../helpers/Utilities'
import { titleUnableToSendDetail, titleCommentHasBeenSent } from  '../helpers/TitleLabel'

import { sendAppReports } from  '../helpers/SendMailUtilities'
import { getCurrentLocationbyGEO } from  '../helpers/QueriesGeneral'

const GetInTouch = () => {
   
    const dispatch = useDispatch();

    //const MAP_API = process.env.REACT_APP_google_map_key_api;

    let defaultFormValues = {
        name : '',
        email : '',
        mobile : '',
        comment : '',
    }

    const fieldsChecker = [ 'name', 'email', 'comment' ];

    const [ formValues, setFormValues ] = useState(defaultFormValues);
    const [ geoLocation, setGeoLocation ] = useState({});

    const pushValue = (e, fieldName ) =>{
        fieldName = fieldName.toLowerCase();
        let value = e.target.value;

        let formValuesTemp = formValues;

        if ( fieldName==='name'){
            formValuesTemp = { ...formValuesTemp, ...{ name : value } };
        }else if ( fieldName==='email'){
            formValuesTemp = { ...formValuesTemp, ...{ email : value } };
        }else if ( fieldName==='mobile'){
            formValuesTemp = { ...formValuesTemp, ...{ mobile : value } };
        }else if ( fieldName==='comment'){
            formValuesTemp = { ...formValuesTemp, ...{ comment : value } };
        }

        jQuery('[name="'+fieldName+'"]').closest('.form-group').removeClass('error');
        setFormValues(formValuesTemp);
        
    }


    const handleSubmitSendReport = async () => {
        
        dispatch( showLoader(0) );

        const button_name = 'btn-send-report';
        UtilitiesHelper.elementDisabledStatus(button_name, false );

        let formValuesTemp = formValues;
        let errorCounter = 0;
        Object.entries(formValues).map( ([ key, value]) => { 
            if ( fieldsChecker.includes(key) ){
                if ( UtilitiesHelper.isEmpty(value )){
                    jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                    errorCounter++;

                }else if (key=='email'){
                    if ( !UtilitiesHelper.isEmail(value )){
                        jQuery('[name="'+key+'"]').closest('.form-group').addClass('error');
                        errorCounter++;
                    }
                }
            }
        });
    
        if ( errorCounter>0 ){  return false; }

        const browerAgentNane = UtilitiesHelper.getBrowerAgent();
        let lat_long = '';

        if ( geoLocation.hasOwnProperty('latitude') ){
            lat_long = geoLocation.latitude.toString()+','+geoLocation.longitude.toString();
        }

        formValuesTemp = { 
                        ...formValuesTemp,
                        ...{ device :  browerAgentNane.toString(), browser : window.navigator.userAgent, lat_long : lat_long }
                    };
                    

        dispatch( showLoader(1) );
        UtilitiesHelper.elementDisabledStatus(button_name, true );
        const responseDetail = await sendAppReports(formValuesTemp );

        dispatch( showLoader(0) );
        UtilitiesHelper.elementDisabledStatus(button_name, false );

        if ( parseInt(responseDetail.status)===0 ){
            UtilitiesHelper.messagePopup('Error', titleUnableToSendDetail );

        }else{
            UtilitiesHelper.messagePopup('Success', titleCommentHasBeenSent );
            setFormValues(defaultFormValues);
        }
    }


    useEffect( async () => {
        dispatch( pageTitle('Get In Touch') );
        dispatch( headerType(1) );

        const responseDetail = await getCurrentLocationbyGEO();
        setGeoLocation(responseDetail);
        
    }, []);

    return (
        <>
            <div className="breadcrumbs">
                    <div className="container breadcrumbs-content">
                    <ul className="breadcrumbs-nav">
                        <li>
                            <div className="current">Get In Touch</div>
                        </li>
                    </ul>
                </div>

            </div>

            <div className="page-wrap px-4 px-sm-5">
                <div className="container py-4">

                   <h3 className="ff-gotham-book fs-18 mt-2">write to us</h3>
                   <p>you can fill the below contact form and we make sure to get back to you as soon as possible.</p>

                    <div className="form-theme col-sm-6 pl-0 my-5 text-lowercase">
                        <div className="form-group">
                            <input type="text" name="name" className="form-control" placeholder=" " value={ formValues.name }  onChange={ (e) => pushValue( e, 'name') } ></input>
                            <label className="form-label">name</label>
                        </div>
                        <div className="form-group">
                            <input type="email" name="email" className="form-control" placeholder=" " value={ formValues.email }  onChange={ (e) => pushValue( e, 'email') } ></input>
                            <label className="form-label">email</label>
                        </div>
                        <div className="form-group optional">
                            <input type="number" name="mobile" className="form-control" placeholder=" " value={ formValues.mobile }  onChange={ (e) => pushValue( e, 'mobile') } ></input>
                            <label className="form-label">mobile</label>
                            <div className="optional-label">Optional</div>
                        </div>
                        <div className="form-group">
                            <textarea name="comment" className="form-control" rows="5" placeholder=" " value={ formValues.comment }  onChange={ (e) => pushValue( e, 'comment') } ></textarea>
                            <label className="form-label bg-white z-index-1">comment</label>
                        </div>

                        <div className="mt-5">
                            <button type="button" id="btn-send-report" className="btn-theme-black text-lowercase button-has-loader" onClick={ () => handleSubmitSendReport() }>submit</button>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}

export default GetInTouch;